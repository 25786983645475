<template>
    <div class="rightBottom">
        <div class="content">
            行业动态
        </div>
        <div style="color: #fff;height: 100%;margin-top: 5px;">
            <div v-for="(item, index) in list" :key="index">
                <div class="line">{{ item }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: ['《工业领域数据安全能力提升实施方案（2024—2026年）》','关于2023年工业互联网试点示范名单的公 2024.02.06', '什么是新质生产力?一图全解 2024.02.05', '工信部印发《工业领域数据安全能力提升实施>2024.02.26', '关于2023年工业互联网试点示范名单的公 2024.02.06']
        };
    },
    methods: {

    }
}
</script>
<style scoped lang="less">
.rightBottom {
    font-family: "黑体", "SimHei", sans-serif; 
    background-color: #3d618e;

    margin: 42px 20px 20px;
    width: 19.26vw;
    height: 20vh;
    background: linear-gradient(90deg, rgba(17, 41, 106, 0.9) 0%, rgba(13, 16, 62, 0.6) 50%, rgba(17, 41, 106, 0.9) 100%);
    border-radius: 8px 8px 8px 8px;
    position: relative;
    padding: 0px 24px 33px;

    .content {
        position: relative;
        left: 25%;
        text-align: center;
        color: #fff;
        padding-top: 8px;
        box-sizing: border-box;
        width: 10vw;
        height: 31px;
        background: url('../../../assets/img/board/title.png') no-repeat;
        background-size: 100% 100%;

    }
    .line{
    width: 100%; overflow: hidden;
    font-size: 16px;

     white-space: nowrap;
     text-overflow: ellipsis;
     margin-bottom: 15px;
}
}
</style>
  
  