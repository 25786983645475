<template>
  <div class="leftTop">
    <div class="content">
      <div class="line">
        <div class="each">
          <div class="top">876</div>
          <div class="foot">联网设备</div>
        </div>
        <div class="each">
          <div class="top">4</div>
          <div class="foot">数字化车间</div>
        </div>
      </div>
      <div class="line">
        <div class="each">
          <div class="top">26</div>
          <div class="foot">服务企业</div>
        </div>
        <div class="each">
          <div class="top">64</div>
          <div class="foot">走访诊断企业</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.leftTop {
  font-family: "黑体", "SimHei", sans-serif; 
  background-color: #3d618e;

  margin: 10px 0px 20px 20px;
  width: 19.26vw;
  height: 20vh;
  background: linear-gradient(90deg, rgba(17, 41, 106, 0.9) 0%, rgba(13, 16, 62, 0.6) 50%, rgba(17, 41, 106, 0.9) 100%);
  border-radius: 8px 8px 8px 8px;
  position: relative;
  padding: 33px 24px;
  .content {
    color: #fff;
    width: 100%;
    height: 20vh;
    background: url('../../../assets/img/board/leftTop.png') no-repeat;
    background-size: 100% 100%;
    .line {
      display: flex;
      justify-content: space-around;align-items: center;
      color: #fff;
      width: 100%;
      height: 10vh;
      
      .each {
        height: 50px;
        width: 40%;
        text-align: center;

        .top {
          font-size: 24px;
          font-weight: bold;
        }

        .foot {
          font-size: 16px;
          color: #FFFFFF;
          opacity: 0.59;
          margin-top: 5px;
        }
      }
    }
  }
}</style>

