<template>
    <div class="rightTop">
        <div class="content">
            能力组件
        </div>
        <div class="text-container">
            <div @click="go('LowCodePlatformDs')">
                <transition name="move-fade" enter-active-class="move-fade-enter-active"
                    leave-active-class="move-fade-leave-active">
                    <div v-if="showText"  class="each" >低代码平台</div>
                </transition>
            </div>
            <div >
                <transition name="move-fade" enter-active-class="move-fade-enter-active"
                    leave-active-class="move-fade-leave-active">
                    <div v-if="showText" @click="go('IOTPlatformDs')" class="each2">IOT平台</div>
                </transition>
            </div>
            <div>
                <transition name="move-fade" enter-active-class="move-fade-enter-active"
                    leave-active-class="move-fade-leave-active">
                    <div v-if="showText" class="each3" @click="go('BIPlatformDs')">BI平台</div>
                </transition>
            </div>
        </div>
        <div class="body">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showText: true, // 控制文字是否显示的标志  
        };
    },
    mounted() {
        // // 在组件挂载后启动过渡  
        setInterval(() => {
            this.showText = !this.showText; // 在一段时间后隐藏文字，触发过渡效果  
        }, 3000); // 例如，3秒后隐藏文字  
    },
    methods: {
        go(info) {
            this.$router.push({ name: info })
        }
    }
}
</script>
<style scoped>
.text-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    color: #fff;
    /* 或者你需要的任何高度 */
}

/* 定义进入和离开过渡的初始状态 */
.move-fade-enter,
.move-fade-leave-to {
    opacity: 0;
    transform: translateX(0);
}

/* 定义进入过渡的结束状态 */
.move-fade-enter-active {
    transition: opacity 1s, transform 1s;
}

/* 定义离开过渡的开始状态 */
.move-fade-leave-active {
    position: absolute;
    transition: opacity 1s, transform 1s;
}

/* 离开过渡时，元素向左移动并逐渐淡出 */
.move-fade-leave-active {
    transform: translateX(-50%);
    opacity: 0;
}
</style>
<style scoped lang="less">
.each {
    background: url('../../../assets/img/board/greenLine.png') no-repeat;
    background-size: 100% 100%;
    width: 120px;
    height: 25px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 35%;
    top: 5%;
    cursor: pointer;
    z-index: 2;
}

.each2 {
    background: url('../../../assets/img/board/yellowLine.png') no-repeat;
    background-size: 100% 100%;
    width: 120px;
    height: 25px;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 70%;
    left: 10%;
    z-index: 2;
    cursor: pointer;

}

.each3 {
    background: url('../../../assets/img/board/blueLine.png') no-repeat;
    background-size: 100% 100%;
    width: 120px;
    height: 25px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 60%;
    top: 70%;
    cursor: pointer;
    z-index: 2;
}

.rightTop {
    font-family: "黑体", "SimHei", sans-serif; 
    margin:10px 20px 20px ;
    width: 19.26vw;
    height: 24vh;
    background: linear-gradient(90deg, rgba(17, 41, 106, 0.9) 0%, rgba(13, 16, 62, 0.6) 50%, rgba(17, 41, 106, 0.9) 100%);
    border-radius: 8px 8px 8px 8px;
    position: relative;
    padding: 0px 24px 33px;
    background-color: #3d618e;


    .content {
        position: relative;
        left: 25%;
        text-align: center;
        color: #fff;
        padding-top: 8px;
        box-sizing: border-box;
        width: 10vw;
        height: 31px;
        background: url('../../../assets/img/board/title.png') no-repeat;
        background-size: 100% 100%;

    }

    .body {
        background: url('../../../assets/img/board/sj.png') no-repeat;
        background-size: 100% 100%;
        width: 60%;
        height: 70%;
        position: absolute;
        left: 23%;
        top: 40px;
        color: #fff;
    }
}
</style>
  
  