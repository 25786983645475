<template>
    <div class="rightCenter">
        <div class="content">
            设备种类及数量
        </div>
        <div class="rightCenterChart">
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
    data() {
        return {
            showText: true, // 控制文字是否显示的标志  
        };
    },
    mounted() {
        this.rightCenterChart()
    },
    methods: {
        rightCenterChart() {
            const myChart = echarts.init(document.querySelector('.rightCenterChart'))
            const sdata = [{
                name: '冲压设备',
                value: 135,
                itemStyle: { color: '#ff3962' }
            }, {
                name: '焊接设备',
                value: 64,
                itemStyle: { color: '#f2fe3a' }
            }, {
                name: '行车',
                value: 2,
                itemStyle: { color: '#16eb7f' }
            }, {
                name: '激光切割',
                value: 2,
                itemStyle: { color: '#ffd55b' }
            }, {
                name: '危险气体检测仪',
                value: 26,
                itemStyle: { color: '#0c88da' }
            }, {
                name: '其他设备',
                value: 347,
                itemStyle: { color: '#63bc34' }
            }]

            const option = {
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    right: '30',
                    top: '10',
                    orient: 'vertical',
                    data: sdata,
                    textStyle: {
                        color: '#fff',
                        fontSize: '12',
                        fontFamily: '"黑体", "SimHei", sans-serif'
                    },
                    formatter: function (name, index) {
                        const arr = sdata.filter(val => val.name == name)
                        let num = arr[0].value
                        return `${name} ${num}台`;
                    }
                },
                series: [
                    {
                        name: '设备种类及数量',
                        type: 'pie',
                        // radius: [0, 140],
                        center: ['25%', '50%'],
                        roseType: 'radius',
                        itemStyle: {
                            borderRadius: 5
                        },
                        label: {
                            show: false
                        },
                        data: sdata
                    }
                ]
            };
            myChart.setOption(option)
        },
    }
}
</script>

<style scoped lang="less">
.rightCenter {
    font-family: "黑体", "SimHei", sans-serif; 
    margin:42px 20px ;
    width: 19.26vw;
    height: 20vh;
    background: linear-gradient(90deg, rgba(17, 41, 106, 0.9) 0%, rgba(13, 16, 62, 0.6) 50%, rgba(17, 41, 106, 0.9) 100%);
    border-radius: 8px 8px 8px 8px;
    position: relative;
    padding: 0px 24px 33px;
    background-color: #3d618e;


    .content {
        position: relative;
        left: 25%;
        text-align: center;
        color: #fff;
        padding-top: 8px;
        box-sizing: border-box;
        width: 10vw;
        height: 31px;
        background: url('../../../assets/img/board/title.png') no-repeat;
        background-size: 100% 100%;

    }

    .rightCenterChart {
        height: 100%;
        width: 100%;
    }
}
</style>
  
  