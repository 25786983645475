<template>
    <div class="leftBottom">
        <div class="content">
            项目获奖情况
        </div>
        <div style="color: #fff;height: 100%; ">
            <div class="scroll-container" ref="scrollContainer">
                <ul class="scroll-list">
                    <li v-for="(item, index) in list" :key="index" class="line"
                        >{{ item }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: ['浙江省科技型中小企业证书', '入库浙江省第一批中小企业专业化服务机构名单',
                '2023年国家科技型中小企业',
                '2023年省级产业数字化服务商名单', '2023年入选舟山市民营企业产业数字化服务商', '2021年浙江省新智造公共服务应用第一批入库供应商',
                '2020年舟山市“5313”行动计划科技创业领军人才项目', '2020年舟山市工业互联网平台示范项目', '2020年舟山市中小微企业创新创业大赛优胜奖',
                '2020年舟山市智能制造工程服务机构（第三批）', '2020年省级工业互联网平台创建名单'],
            scrollContainer: null,
        };
    },
    mounted() {
        this.scrollContainer = this.$refs.scrollContainer;
        this.scrollContainer.style.animation = 'scroll 10s linear infinite';
    },
    methods: {

    }
}
</script>
<style scoped>
.scroll-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.scroll-list {
    /* display: flex; */
    list-style: none;
    padding: 0;
    margin: 0;
    animation: scroll 20s linear infinite;

}

.line {
    margin-bottom: 12px;
    padding: 5px;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}
</style>
<style scoped lang="less">
.leftBottom {
    margin: 42px 20px 20px;
    width: 19.26vw;
    height: 20vh;
    background: linear-gradient(90deg, rgba(17, 41, 106, 0.9) 0%, rgba(13, 16, 62, 0.6) 50%, rgba(17, 41, 106, 0.9) 100%);
    border-radius: 8px 8px 8px 8px;
    position: relative;
    padding: 0px 24px 33px;
    font-family: "黑体", "SimHei", sans-serif; 
    font-size: 16px;
    background-color: #3d618e;
    .content {
        position: relative;
        left: 25%;
        text-align: center;
        color: #fff;
        padding-top: 8px;
        box-sizing: border-box;
        width: 10vw;
        height: 31px;
        background: url('../../../assets/img/board/title.png') no-repeat;
        background-size: 100% 100%;

    }
}
</style>
  
  