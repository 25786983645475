<template>
    <div class="leftCenter">
        <div class="content">
            案例企业
        </div>
        <div style="height: 20vh;color: #fff;margin-top: 10px;box-sizing: border-box;" @click="go">
            <div>
                <img :src="form.img" style="height: 130px;width: 100%;"/>
            </div>
            <div style="margin-top: 10px;text-align: center;font-size: 18px;font-weight: bold;">
                {{ form.name }}
            </div>

        </div>
    </div>
</template>
<script>
import imgJX from '../../../assets/img/CustomerJXSponge/banner@2x.png'
import imgLH from '../../../assets/img/CustomerLH/banner@2x.jpg'
import imgRC from '../../../assets/img/CustomerRC/banner@2x.png'
import imgHS from '../../../assets/img/CustomerHS/banner@2x.jpg'
import imgYL from '../../../assets/img/CustomerYL/banner@2x.jpg'
import imgNX from '../../../assets/img/CustomerNX/公司@2x.png'
import imgJS from '../../../assets/img/AboutUs/banner@2x.png'
import imgBS from '../../../assets/img/CustomerBS/banner@2x.jpg'

export default {
    data() {
        return {
            timer:null,
            form: {},
            index: 0,
            list:[{
                img:imgJX,
                name:'岱山县金鑫海绵制品有限公司',
                baseInfo:'岱山县金鑫海绵制品有限公司于2000年05月26日。公司经营范围包括海绵制品、塑料制品、汽车配件、五金配件、座椅等',
                code:[],
                url:'CustomerJXSpongeDs'
            },{
                img:imgLH,
                name:'浙江绿海制盐有限责任公司',
                baseInfo:'浙江绿海制盐有限责任公司是省盐业集团公司的直属控股子公司，是国家食盐定点生产企业，也是全省仅有的传统浙盐生产加工企业和纯天然绿色海盐、营养日晒盐、洗化盐、保健盐生产企业。公司拥有国内先进的现代化食盐小包装全自动生产线 2 条，年产能 10 万吨，拥有洗化盐生产线、海鲜精生产线各一条，并建有仓储、配送中心和研发中心，产品已通过 ISO9001 质量管理体系及 ISO14001 环境管理体系认证。公司被省国资委列为重点培育上市对象。',
                code:['2021年岱山县数字化标杆企业。'],
                url:'CustomerLH'
            },{
                img:imgRC,
                name:'浙江荣畅船舶修造有限公司',
                baseInfo:'荣畅船舶由平太荣远洋集团投资组建，具有年造船15万载重吨的生产能力，并为国内外远洋渔业兄弟企业的船只提供配套修理服务，将助推舟山市建设全国最大的远洋渔船修造中心。',
                code:['舟山市2022年工业互联网标杆项目'],
                url:'CustomerRC'
            },{
                img:imgHS,
                name:'舟山惠生海洋工程有限公司',
                baseInfo:'舟山惠生海洋工程有限公司从事包括海洋钻井、生产平台的设计与建造，海洋桩基式固定钻井、生产平台及模块的设计与建造，海上浮式生产储卸油装置、海上浮式储卸油装置、液化天然气船、液化石油气船的设计与建造，豪华游轮的设计与建造，港口机械装备的设计与建造，各类海洋工程平台的改造与修理。',
                code:[],
                url:'CustomerHS'
            },{
                img:imgYL,
                name:'浙江友联修造船有限公司',
                baseInfo:'浙江友联修造船有限公司（简称舟山友联）,原名浙江东邦修造船有限公司，成立于2007年5月30日，位于岱山县衢山镇岛扎村潮头门岙，距上海国际航运中心洋山深水港区仅15海里，以各类船舶修理、船舶改装以及海洋工程为主营业务。2018年4月1日招商工业集团正式接管浙江友联。其正式更名为浙江友联修造船有限公司。',
                code:['舟山市数字化标杆项目       2023年度市级数字工厂 '],
                url:'CustomerYL'
            },{
                img:imgNX,
                name:'舟山宁兴船舶修造有限公司',
                baseInfo:'舟山宁兴船舶修造有限公司成立于 2020 年，自公司创立之初，逐步确立了“小、精、专”的经营发展方向，明确了以液货船、冷藏船、挖泥船、拖轮等特种船舶为特色的产品定位。公司始终对标行业标杆，不断向现代造船模式迈进，提出公司“通过生产模式现代化、经营管理数字化的升级改造，打造‘绿色’、‘高效’的现代船舶制造基地” 发展愿景。',
                code:['舟山市2023年工业互联网标杆项目'],
                url:'CustomerNX'
            },{
                img:imgJS,
                name:'捷胜海洋装备股份有限公司',
                baseInfo:'捷胜海洋专业从事海洋装备系列产品的研发、生产、销售和服务，产业主要涉及海洋渔业装备、海洋科考装备、海事海工装备、及玻璃钢船舶技术与装备，是海洋装备领域的高新技术企业。本着“合作共赢”的经营理念，公司与国内海洋产业相关的科研院所建立了良好的产学研合作关系，与国内外知名的海洋装备制造品牌商缔结战略联盟，通过不断承担国家各类科研项目，极大的提升了自主研发能力。',
                code:[],
                url:'CustomerJS'
            },{
                img:imgBS,
                name:' 浙江宝塑环保科技有限公司',
                baseInfo:'浙江宝塑环保科技有限公司是一家从事技术服务,技术开发,技术咨询等业务的公司。企业的主要经营：资源再生利用技术研发；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；塑料制品销售；塑料制品制造；工程塑料及合成树脂销售；包装专用设备制造；模具制造；专用设备制造（不含许可类专业设备制造）；合成材料制造（不含危险化学品）；新材料技术研发；塑料包装箱及容器制造；货物进出口等。',
                code:[],
                url:'CustomerBS'
            }]
        };
    },
    created() {
        this.getForm()
        this.timer=setInterval(() => {
            this.getForm()
        }, 10000)
    },
    beforeDestroy() {
    clearInterval(this.timer);        
    this.timer = null;
},
    methods: {
        //跳转相应的企业简介
        go() {
            window.open(
              `http://ds.dtsum.com/${this.list[this.index-1].url}`,
              '_blank'
            );
        },
        getForm() {
            if (this.list.length > this.index) {
                this.form = this.list[this.index]
                this.index++
            } else if(this.list.length ==this.index) {
                this.index = 0
            }else{
                this.form = this.list[this.index]
            }
        }
    }
}
</script>
<style scoped lang="less">
.baseInfo{
    height: 50%;
    max-height: 200px;overflow: scroll;overflow-x:hidden;
    margin-top: 5px;
    opacity: 0.9;
    font-size: 16px;
}
/* 修改纵向滚动条样式 */  
.baseInfo::-webkit-scrollbar {  
  width: 5px; /* 滚动条的宽度 */  
}  
  
.baseInfo::-webkit-scrollbar-track {  
  background: #10255f; /* 滚动条轨道的背景色 */  
}  
  
.baseInfo::-webkit-scrollbar-thumb {  
  background: #112660; /* 滚动条滑块的背景色 */  
}  
  
.baseInfo::-webkit-scrollbar-thumb:hover {  
  background: #555; /* 鼠标悬停时滚动条滑块的背景色 */  
}
.leftCenter {
    margin: 42px 20px;
    width: 19.26vw;
    height: 20vh;
    background: linear-gradient(90deg, rgba(17, 41, 106, 0.9) 0%, rgba(13, 16, 62, 0.6) 50%, rgba(17, 41, 106, 0.9) 100%);
    border-radius: 8px 8px 8px 8px;
    position: relative;
    padding: 0px 24px 33px;
    font-family: "黑体", "SimHei", sans-serif; 
    background-color: #3d618e;

    .content {
        position: relative;
        left: 25%;
        text-align: center;
        color: #fff;
        padding-top: 8px;
        box-sizing: border-box;
        width: 10vw;
        height: 31px;
        background: url('../../../assets/img/board/title.png') no-repeat;
        background-size: 100% 100%;
    }
}
</style>
  
  