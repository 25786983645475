<template>
  <div id="head" style="position: relative;height: 90px;">
    <div class="top">
      <div class="time">{{ time }}</div>
      <div class="title">
        岱山汽船配工业互联网平台驾驶舱
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: ''
    };
  },
  async mounted() {
    // this.time = formatDate(new Date().getTime(), 'yyyy-MM-dd hh:mm')
    console.log(this.time)
    // this.getData()
    this.time=this.getTime()
   
    setInterval(()=>{
       this.time=this.getTime()
    },60000)
  },
  methods: {
    getTime(date = new Date()) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? ('0' + minute) : minute;
      var second = date.getSeconds();
      second = second < 10 ? ('0' + second) : second;
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute ;
    }

  }
}
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.top {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  color: #fff;
  width: 100vw;
  height: 90px;
  background: url('../../../assets/img/board/backtopscreen.png') no-repeat;
  background-size: 100% 100%;
  font-family:'黑体', 'SimHei', sans-serif;

  .time {
    position: absolute;
    top: 10px;
    right: 36px;
    font-size: 24px;
  }

  .title {
    margin: 0 auto;
    font-size: 36px;
    font-weight: 700;
    padding-top: 20px;
    font-family:'黑体', 'SimHei', sans-serif;
  }

}
</style>

