<template>
  <div class="board">
    <top style="position: absolute;z-index: 2;"/>
    <centerMap style="position: absolute;z-index: 1;"></centerMap>
      <div style="position: absolute;z-index: 2;top: 6%;left: 0px;">
        <leftTop />
        <leftCenter />
        <leftBottom />
      </div>
      <div style="position: absolute;z-index: 2;top: 6%;right: 0px;">
        <rightTop/>
        <rightCenter/>
        <rightBottom/>
      </div>
  </div>
</template>
<script>
import top from './components/top.vue'
import leftTop from './components/leftTop.vue'
import leftCenter from './components/leftCenter.vue'
import leftBottom from './components/leftBottom.vue'
import rightTop from './components/rightTop.vue'
import rightCenter from './components/rightCenter.vue'
import rightBottom from './components/rightBottom.vue'
import centerMap from './components/centerMap.vue'
export default {
  name: "board",
  components: {
    leftTop, top, leftCenter, leftBottom, rightTop,rightCenter,rightBottom,centerMap
  },
  data() {
    return {

    };
  },
  methods: {

  }
};
</script>
<style lang="less" scoped>
.board {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #0c1a2d;
  box-sizing: border-box;
}
</style>
