<template>
  <div class="centerMap">
    <div id="container" style="width:100vw;height:calc(100vh - 10px);"></div>

    <el-dialog :visible.sync="dialogVisible" width="50%" :showClose="false" append-to-body @close="close"
      custom-class="no-header-dialog">
      <img :src="currentImg.img" style="width: 100%;height: 100%;" :key="currentIndex">
    </el-dialog>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader"
import hs1 from '../../../assets/img/board/hs/01.jpg'
import hs2 from '../../../assets/img/board/hs/02.jpg'
import lh1 from '../../../assets/img/board/lh/01.png'
import lh2 from '../../../assets/img/board/lh/02.png'
import nx1 from '../../../assets/img/board/nx/01.png'
import nx2 from '../../../assets/img/board/nx/02.jpg'
import rc1 from '../../../assets/img/board/rc/01.png'
import rc2 from '../../../assets/img/board/rc/02.png'
import rc3 from '../../../assets/img/board/rc/03.png'
import rc4 from '../../../assets/img/board/rc/04.png'
import bs1 from '../../../assets/img/board/bs/01.png'
import bs2 from '../../../assets/img/board/bs/02.png'
import bs3 from '../../../assets/img/board/bs/03.png'
import bs4 from '../../../assets/img/board/bs/04.png'
import bs5 from '../../../assets/img/board/bs/05.png'
import yl1 from '../../../assets/img/board/yl/01.png'
import yl2 from '../../../assets/img/board/yl/02.png'
import yl3 from '../../../assets/img/board/yl/03.png'
import yl4 from '../../../assets/img/board/yl/04.png'
import jx from '../../../assets/img/CustomerJXSponge/驾驶舱@2x.png'
export default {
  data() {
    return {
      timer: null,
      current: [],
      currentImg: {
      },
      currentIndex: 0,
      dialogVisible: false,
      map: null,
      district: null,
      curent: 0,
      all: [],
      center: [122.17797, 30.291942],
      makerList: [// 标记点数组
      {
        lnglats:[121.546279,30.005842],
        img: [],
        name:'宁波金环紧固件有限公司',
      },
      {
        lnglats:[122.184074,30.300552],
        img: [jx],
        name:'岱山县金鑫海绵制品有限公司',
      },
      {
        lnglats:[122.174777,30.306087],
        name:'捷胜海洋工程装备有限公司',
        img:[]
      },
        {
          lnglats: [122.201037, 30.175799],
          img: [hs1, hs2],//惠生
          name:'舟山惠生海洋工程有限公司',
        }, {
          lnglats: [122.151986, 30.255698],
          img: [lh1, lh2],//绿海
          name:'浙江绿海制盐有限责任公司'
        }, {
          lnglats: [122.099161, 30.278518],
          img: [nx1, nx2],//宁兴
          name:'舟山宁兴船舶修造有限公司'
        }, {
          lnglats: [122.120978, 30.267807],
          img: [rc1, rc2, rc3, rc4],//荣畅
          name:'浙江荣畅船舶修造有限公司'
        }, {
          lnglats: [122.287898, 30.472946],
          img: [yl1, yl2, yl3, yl4],//友联
          name:'浙江友联修造船有限公司'
        }, {
          lnglats: [122.174243, 30.310181],
          img: [bs1, bs2, bs3, bs4, bs5],//宝塑
          name:'浙江宝塑环保科技有限公司'
        }
      ],
    }
  },
  async mounted() {
    document.title = '岱山汽船配工业互联网平台';  
    window._AMapSecurityConfig = { securityJsCode: '2a1a1816914fc521f5a2f4e7bc0c5676', }
    await this.initMap();
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    
    close() {
      this.dialogVisible = false
      clearInterval(this.timer);
    },
    initMap() {
      AMapLoader.load({
        key: '8e67eefc96f66e0358b358a26455856b', // 申请好的Web端开发者Key，首次调用 load 时必填
        //2.0版本太卡了 ，所以使用的1.4.0版本  其插件也有不同  如：ToolBar
        version: '1.4.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        resizeEnable: true,
        plugins: [
          'AMap.ToolBar', //工具条
          'AMap.Scale', // 比例尺
          'AMap.Geolocation', //定位
          'AMap.ControlBar',
          'AMap.Geocoder',
          'AMap.Marker', //点标记
          'AMap.CitySearch', //搜索
          'AMap.AutoComplete',
          'AMap.InfoWindow', //信息弹窗
          'AMap.DistrictSearch'
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          // [122.183639, 30.355660], // 右上角经纬度  
          // [122.054784, 30.236360] // 左下角经纬度  
          this.map = new AMap.Map('container', {
            mapStyle: 'amap://styles/f4838053e76a8b683338f7e238b4a73e',
            resizeEnable: true,
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 12, //初始化地图级别
            center: this.center, // 初始化中心点坐标
          })
          this.drawMarker() //标记点
        })
        .catch((e) => {
          console.log(e)
        })
    },
    //标记点
    drawMarker() {
      let img = require("../../../assets/img/board/icon.png");//绿色角标
      // let img = 'https://img.soogif.com/MSp9sahCaZxFya9dZ121qCg3UliBkI0R.gif'//绿色角标
      let icon = new AMap.Icon({
        size: new AMap.Size(50, 50), // 图标尺寸
        image: img, // Icon的图像
        imageSize: new AMap.Size(25, 25), // 根据所设置的大小拉伸或压缩图片
      })
      this.makerList.forEach((item, index) => {
        // 遍历生成多个标记点
        this.marker = new AMap.Marker({
          map: this.map,
          zIndex: 9999999,
          icon: icon, // 添加 Icon 实例
          offset: new AMap.Pixel(27.5, -65), //icon中心点的偏移量
          position: item.lnglats // 经纬度对象new AMap.LngLat(x, y)，也可以是经纬度构成的一维数组[116.39, 39.9]
        })
        this.map.add(this.marker)
        this.markers = []
        this.markers.push(this.marker)
        let that = this
        // 单击弹窗
        AMap.event.addListener(this.marker, 'click', function (e) {
          that.openImg(item)
        })
        // 文本标记
        var text = new AMap.Text({
          text: item.name, //标记显示的文本内容
          anchor: "center", //设置文本标记锚点位置
          cursor: "pointer", //指定鼠标悬停时的鼠标样式。
          angle: 0, //点标记的旋转角度
          style: {
            //设置文本样式，Object 同 css 样式表
            "background-color": "rgba(0,0,0,0)",
            "border-width": 0,
            "text-align": "center",
            "font-size": "14px",
            "color": "#d09e11",
            "margin-top":'-55px',
            "margin-left":'280px',
            "font-family":"'黑体', 'SimHei', sans-serif",
          },
          position: item.lnglats, //点标记在地图上显示的位置
        });
        text.setMap(this.map); //将文本标记设置到地图上
        // 单击弹窗
        AMap.event.addListener(text, 'click', function (e) {
          that.openImg(item)
        })

      })
    },
    openImg(item) {
      this.dialogVisible = true
      this.currentIndex = 0
      this.curent = item.img
      this.getForm()
      this.timer = null;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getForm()
      }, 5000)
    },
    getForm() {
      if (this.currentIndex < this.curent.length) {
        this.currentImg.img = this.curent[this.currentIndex]
        this.currentIndex++
      } else {
        this.currentIndex = 0
      }
    }
  }
}
</script>

<style>
.no-header-dialog .el-dialog__header {
  display: none;
}

.el-dialog__body {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
}
</style>

<style scoped lang="less">
.centerMap {

  position: absolute;
  //    border: 1px solid green;
}
</style>

<style>
.tmap-zoom-control {
  display: none !important;
}

.rotate-circle,
.rotate-circle * {
  display: none !important;
}

.tmap-scale-control {
  display: none !important;
}

.logo-text {
  display: none !important;
}
</style>